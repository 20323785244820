/* eslint-disable prettier/prettier  */
// React
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dynamic from "next/dynamic";
import Text, { TEXT_COLOR, TEXT_SIZE, TEXT_KIND, TEXT_DISPLAY, TEXT_ALIGN } from "@/bit/components/components.text"
import CustomLink from "@/bit/components/components.custom-link";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";

const NewConditions = () => {
    const dispatch = useDispatch();
    const { context } = useSelector(
        (state) => state.main
      );
    const [orionInfo, setOrionInfo] = useState(null);
    const taggingInfo = {
        "componentName": "new-conditions",
        "componentVariation": "all",
        "componentProduct": "tv",
    }

    const handleConditions = async () => {
        const moduleAlias = await import("@gcloud-npm/adabyron.orion.get-by-alias");
        const getByAlias = moduleAlias.default;
        let orionInfoData = null;
        if(!orionInfo) {
          if ( context) {
            orionInfoData = await getByAlias({
                alias: "subpvp-tv",
                context,
                build: false,
            });
            setOrionInfo(orionInfoData);
          }
        } else {
          orionInfoData = orionInfo;
        }

        const LegalModal = dynamic(() => import("@/component/legal-modal"));
        dispatch(
        modalActions.addModal({
            id: "legal-modal",
            Element2Show: LegalModal,
            modalProps: {
            closeIdByClickingOutside: "closeAll",
            contentPosition: "right--top",
            opacityLayoutColor: "blur",
            inSceneEffect: "fadeIn",
            contentInsideContainer: false,
            outSceneEffect: "fadeOut",
            closeByClickingOutside: true,
            scrollAllowed: false,
            createAnchor: false,
            },
            elementProps: {
            title: {
                "kind": "heading",
                "level": 3,
                "size": "t5",
                "color": "blue_dark",
                "fontType": "font-regular",
                "text": orionInfoData.data.fields.condiciones_legales_subpvp.fields.H1Flyout
            },
            description: {
                "kind": "p",
                "size": "body1",
                "color": "grey6",
                "fontType": "font-regular",
                "text": orionInfoData.data.fields.condiciones_legales_subpvp.fields.descriptionFlyout
            },
            taggingInfo: {
            ...taggingInfo,
            "componentProduct": "legal-modal",
            },
            },
        })
        );
    }

  return (
    <>
      <div className="container">
        <Text
            text="Precios válidos hasta el 12/01/25. "
            align={TEXT_ALIGN.LEFT}
            color={TEXT_COLOR.GREY6}
            size={TEXT_SIZE.CAPTION3}
            display={TEXT_DISPLAY.INLINE}
            kind={TEXT_KIND.SPAN}
        />
        <CustomLink
            title="Consultar las bases legales"
            target="_blank"
            taggingInfo={{
            ...taggingInfo,
            "componentContent": "Nuevas condiciones",
            }}
            style={{ display: "contents" }}
            onClick={handleConditions}
        >
            <Text
            text="Ver nuevas condiciones"
            size={TEXT_SIZE.CAPTION3}
            align={TEXT_ALIGN.LEFT}
            display={TEXT_DISPLAY.INLINE}
            kind={TEXT_KIND.SPAN}
            color={TEXT_COLOR.BLUE}
            />
        </CustomLink>
      </div>
      <style jsx>{`
        
      `}</style>
    </>
  );
};

export default NewConditions;
