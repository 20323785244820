import normalizeOrionModule from "@/bit/normalizers/normalizers.orion";
import getByAlias from "@gcloud-npm/adabyron.orion.get-by-alias";

export const normalizerOrion = async ({
  context,
  alias,
  build = false,
  ssrHeaders = {},
}) => {
  let orionInfo = {};
  try {
    if (alias) {
      orionInfo = await getByAlias({
        alias,
        context,
        responseType: "json",
        build,
        ssrHeaders,
      });

      global.logger({
        text: `Tengo algo de respuesta ${global.currentEnv}`,
        severity: global.SEVERITY_TYPES.INFO,
      });

      if (orionInfo) {
        try {
          orionInfo = normalizeOrionModule(orionInfo, "", "", "", "", "");
        } catch (e) {
          console.log(`Algo fue mal normalizando: ${e}`);
        }
      }
    }
  } catch (e) {
    console.log(`Algo fue mal pidiendo a orion: ${e}`);
  }
  return orionInfo;
};

export default normalizerOrion;
