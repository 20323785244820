import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import TextInfo from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";
import Label from "@/bit/components/components.label";
import Price from "@/bit/components/components.price";
import Glyph from "@/bit/components/components.icon-glyph";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";

const Image = dynamic(() => import("@/bit/components/components.c-image"));
const CustomLink = dynamic(() => import("@/bit/components/components.custom-link"));

const Card = (props) => {
  const { index, categories } = props;
  let cardDataCy = "";
  categories &&
    Array.isArray(categories) &&
    categories.map((cat) => (cardDataCy += `${cat} `));
  const taggingInfoButtonContratar = {
    componentName: "tv-card",
    componentVariation: "all",
    componentContent:
      props["Button-1"] && props["Button-1"].label
        ? props["Button-1"].label
        : "",
    componentProduct: props.alias || "",
    eventType: "click",
  };

  const taggingInfoMasInfo = {
    componentName: "tv-card",
    componentVariation: "all",
    componentContent:
      props["Button-3"] && props["Button-3"].label
        ? props["Button-3"].label
        : "",
    componentProduct: props.alias || "",
    eventType: "click",
  };

  const isWebView = process.env.NEXT_PUBLIC_WEB_VIEW === "true";
  const dispatch = useDispatch();

  const handleProductModal = () => {
    const device = getDeviceKindDependsOnViewPort();
    if (
      props["FlyOut-1"].urlIframe &&
      window.top.location === window.self.location
    ) {
      const ModalWrapper = dynamic(() => import("@/bit/components/components.cms-block.cms-modal-page"));

      dispatch(
        modalActions.addModal({
          id: "productoModal",
          Element2Show: ModalWrapper,
          modalProps: {
            closeIdByClickingOutside: "",
            opacityLayoutColor: "blur",
            closeByClickingOutside: true,
            contentPosition:
              device === "MOBILE" ? "center--bottom" : "center--center",
            inSceneEffect:
              device === "MOBILE" || device === "TABLET"
                ? "fadeInBottom"
                : "fadeInRight",
            outSceneEffect:
              device === "MOBILE" || device === "TABLET"
                ? "fadeOutBottom"
                : "fadeOutRight",
            scrollAllowed: false,
            createAnchor: false,
          },
          elementProps: {
            url: props["FlyOut-1"].urlIframe,
          },
        })
      );
    } else if (
      props["FlyOut-1"].urlIframe &&
      window.top.location !== window.self.location
    ) {
      const message = {
        url: props["FlyOut-1"].urlIframe,
        action: "redirect",
      };
      window.parent.postMessage(message, "*");
    } else {
      const TvModal = dynamic(() => import("@/component/tvModal"));
      dispatch(
        modalActions.addModal({
          id: "product-modal",
          Element2Show: TvModal,
          modalProps: {
            closeIdByClickingOutside: "closeAll",
            opacityLayoutColor: "blur",
            closeByClickingOutside: true,
            contentInsideContainer: false,
            contentPosition: "center--center",
            inSceneEffect:
              device === "MOBILE" || device === "TABLET"
                ? "fadeInBottom"
                : "fadeInRight",
            outSceneEffect:
              device === "MOBILE" || device === "TABLET"
                ? "fadeOutBottom"
                : "fadeOutRight",
            scrollAllowed: false,
            createAnchor: false,
          },
          elementProps: {
            ...props["FlyOut-1"],
            "Button-1": props["Button-1"],
            device,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      scrollToElement(window.location.hash.replace(/#/g, ""));
    }
  }, []);

  const scrollToElement = (anchor) => {
    try {
      const element = document.getElementById(anchor);
      const elementHeader = document.getElementsByTagName("header")[0];
      let point2go = element.offsetHeight;
      point2go -= elementHeader.offsetHeight;
      window.scrollTo(0, point2go);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="tv-card"
      data-cy={cardDataCy}
      key={index}
      id={`${props.alias}-place`}
    >
      {props["CustomLink-1"] && (
        <CustomLink
          {...props["CustomLink-1"]}
          title={props["CustomLink-1"].title || ""}
          className="overContent"
          onClick={
            props["Button-3"] &&
            !isWebView &&
            props["Button-3"].label &&
            !props["Button-3"].href
              ? handleProductModal
              : null
          }
          taggingInfo={{
            componentName: "tv-card",
            componentContent: props["CustomLink-1"].title,
            componentVariation: "all",
            componentProduct: props.alias || "",
            eventType: "click",
          }}
          cyData="Card CustomLink-1"
        />
      )}
      <div
        className={`tv-card-img ${
          !props["Image-1"]?.src && props["Label-1"] ? "without-image" : ""
        } `}
      >
        {props["Image-1"] && props["Image-1"].src && (
          <div className="card-container--image aspect-ratio-10-3">
            <Image
              {...props["Image-1"]}
              fill
              objectFit={
                props["Image-1"]?.objectFit
                  ? props["Image-1"].objectFit
                  : "cover"
              }
              cyData={`Card Image-1 ${props["Image-1"].alt}`}
            />
          </div>
        )}
        {props["Label-1"] && props["Label-1"].text ? (
          <div className="tv-card-recommend">
            <TextInfo {...props["Label-1"]} cyData={`Card-${index} Label-1`} />
          </div>
        ) : (
          <div className="tv-card-empty-recommend" />
        )}
      </div>
      <div
        className={`card-content-container ${
          !props["Image-1"]?.src ? "simple-card-content" : ""
        }`}
      >
        <div className="text-container">
          {props["Text-1"] && props["Text-1"].text && (
            <div className="tv-card-text">
              {props["Glyph-1"] && (
                <div className="tv-card-icon">
                  <Glyph {...props["Glyph-1"]} />
                </div>
              )}
              {props["Image-2"] && (
                <div className="tv-card-image">
                  <Image
                    {...props["Image-2"]}
                    fill
                    objectFit={
                      props["Image-2"]?.objectFit
                        ? props["Image-2"].objectFit
                        : "cover"
                    }
                    cyData={`Card Image-2 ${props["Image-2"].alt}`}
                  />
                </div>
              )}
              <TextInfo
                {...props["Text-1"]}
                className="tv-card-title"
                cyData={`Card-${index} Text-1`}
              />
            </div>
          )}
          {props["Text-2"] && props["Text-2"].text && (
            <div className="tv-card-desc">
              <TextInfo {...props["Text-2"]} cyData={`Card-${index} Text-2`} />
            </div>
          )}
        </div>

        <div>
          {props["Label-2"] && props["Label-2"].label && (
            <Label className="promo-label" {...props["Label-2"]} />
          )}

          {props["Text-3"] && props["Text-3"].text && (
            <div className="claim-promo">
              <TextInfo {...props["Text-3"]} cyData={`Card-${index} Text-3`} />
            </div>
          )}

          {(props["Price-1"] || props["Price-2"] || props["Price-Text"]) && (
            <div
              className={`price-container ${
                props["Price-1"] && props["Price-2"] ? "with-promo" : ""
              }`}
            >
              {props["Price-1"] && (
                <Price {...props["Price-1"]} cyData="Card Price-1" />
              )}
              {props["Price-2"] && (
                <Price {...props["Price-2"]} cyData="Card Price-2" />
              )}
              {props["Price-Text"] && (
                <Price {...props["Price-Text"]} cyData="Card Price-Text" />
              )}
            </div>
          )}

          {props["Text-4"] && props["Text-4"].text && (
            <TextInfo
              className="text-post-price"
              {...props["Text-4"]}
              cyData={`Card-${index} Text-4`}
            />
          )}
          {(props["Button-1"] || props["Button-3"]) && (
            <div className="tv-card-buy-button">
              {props["Button-1"] && props["Button-1"].label && (
                <Button
                  {...props["Button-1"]}
                  taggingInfo={taggingInfoButtonContratar}
                  cyData="Card Buetton-1"
                />
              )}
              {props["Button-3"] &&
                !isWebView &&
                props["Button-3"].label &&
                props["Button-3"].href && (
                  <Button
                    {...props["Button-3"]}
                    taggingInfo={taggingInfoMasInfo}
                    cyData="Card Buetton-3"
                  />
                )}
              {props["Button-3"] &&
                !isWebView &&
                props["Button-3"].label &&
                !props["Button-3"].href && (
                  <Button
                    {...props["Button-3"]}
                    className="detail-button"
                    onClick={handleProductModal}
                    taggingInfo={taggingInfoMasInfo}
                    cyData="Card Buetton-3 FlyOut"
                  />
                )}
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .text-container {
          margin-top: 0.5rem;

          :global(.tv-card-title) {
            line-height: 28px;
          }

          :global(.tv-card-text) {
            margin-bottom: 1rem;
          }
          @media screen and (max-width: 768px) {
            :global(.tv-card-title) {
              line-height: 24px;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default Card;
